import './App.css';
import styled from 'styled-components';

function App() {

  const MainConatiner = styled.header`
    background-color: #fff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  `;

  const Title = styled.h2`
    color: black;
    text-align: center;
    font-family: 'Press Start 2P', cursive;
  `
  const Subtitle = styled.h3`
  text-align: center;
    color: black;
  `
  const Item = styled.li`
    color:black;
  `

  const ItemList = styled.ul`
  list-style:none;
  `

  return (
    <MainConatiner>
      <Title className="scale-in-center"> Taquito.LAB </Title>
      <Subtitle className="scale-in-center">Laboratorio de crecimiento digital</Subtitle>
      <lottie-player src="https://assets3.lottiefiles.com/packages/lf20_llpnmgts.json" background="transparent" speed="1" style={{ width: '300px', height: '300px' }} loop autoplay></lottie-player>
      <ItemList>
        <Item className="flicker-in-1">{"{Paginas web 🖥 }"}</Item>
        <Item className="flicker-in-1">{"{Shopify 💰}"}</Item>
        <Item className="flicker-in-1">{"{Aplicaciones 📱}"}</Item>
        <Item className="flicker-in-1">{"{Analisis de datos y SEO 📈}"}</Item>
      </ItemList>
      <a
        className="App-link flicker-in-1"
        href="https://www.instagram.com/taquito.lab/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Contacto
      </a>
    </MainConatiner>
  );
}

export default App;
